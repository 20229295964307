import React from "react"
import { Link, withPrefix } from "gatsby"
import Layout from "../components/layouts/layout"
import HeadMeta from '../components/layouts/headmeta'

const NotFoundPage = () => (
  <Layout>
    <div className="landing-page">
      <HeadMeta metadata={ metadata } />
      <section className="hero hero-landing bg-white" style={{height: "80vh", minHeight: "60vh"}}>
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6">
                <img src="/img/fogg-page-not-found.png" className="img-fluid" alt="Page not found"/>
                <p><small style={{color: 'grey', fontSize: '.5rem'}}>llustration by <a href="https://icons8.com">Ouch.pics</a></small></p>
              </div>
              <div className="col-lg-6">
                <h2 className="text-black">Oh no! We can’t find the page you’re looking for.</h2>
                <p className="hero-text text-dark">You can either head back to our <Link to={withPrefix('/')}>home</Link> or explore through the links below.</p>
              </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export const metadata = {
  title: "Page Not Found | Advanx Health",
  description: "",
  image: "https://www.advanxhealth.com/img/share/blog-index.png",
  url: "https://www.advanxhealth.com"
}

export default NotFoundPage
